import React from 'react'



const ProductCard = ({ title, price, image }) => {
  return (
    <div className=" bg-[#F1F1F1] border border-gray-200 rounded-lg overflow-hidden shadow-sm md:h-[285px] md:w-[285px]">
        <div className='h-[200px] w-[260px] bg-[white] mx-auto mt-3 rounded-md flex justify-center items-center'>
        <img width={174} height={174} src={image} alt={title} className=" object-cover mx-auto" />
        </div>
      <div className="p-4">
        <h3 className="text-gray-800 text-lg font-semibold">{title}</h3>
        <p className="text-indigo-500 text-xl font-bold">{price}</p>
      </div>
    </div>
  )
}

export default ProductCard