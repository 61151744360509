import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Contact = () => {

  const navigate = useNavigate()

  const [clientName, setclientName] = useState("");
  const [clientEmail, setclientEmail] = useState("");
  const [clientPhone, setclientPhone] = useState("");
  const [clientMessage, setclientMessage] = useState("");


  const handleSubmitContact = async (e) => {
    e.preventDefault();
  
    let inputs = { name: clientName, email: clientEmail, phone: clientPhone, message: clientMessage };
    
    try {
      console.log(inputs);
      
      // Seçilen ürünleri post isteği ile gönder
      const response = await axios.post("https://www.pouffe.az/pouffe/contact.php/", inputs);
      
      // Başarılı olduğunda yapılacak işlemler
      toast.success("Form göndərildi");
      
      // Clear form after successful submission
      setclientName('');
      setclientEmail('');
      setclientPhone('');
      setclientMessage('');
      
      navigate('/');
      
    } catch (error) {
      // Hata durumunda yapılacak işlemler
      console.error("Sipariş gönderilirken bir hata oluştu:", error);
      toast.error("Form göndərilərkən xəta baş verdi");
    }
  };
  

  return (
    <>
    <div className='h-40 bg-[#E3D5F5] text-center flex flex-col justify-end'>
   <h1 className='font-semibold text-2xl'>Bizimlə əlaqə saxlayın</h1>
   <p className='mb-5'>Bizə sualınız var? Məhsullarımıza rəy bildirmək istəyirsiniz? Bizə yaza bilərsiz.</p>
    </div>
    <div className="max-w-container w-[80%] mx-auto block md:flex mt-10 mb-[100px]">
  <div className="flex flex-col md:space-y-4 mr-[100px]">
    <p>Əlaqə məlumatlarımız</p>
    <div className="flex items-center space-x-3">
      <i style={{color:'#5A639C'}} className="fa fa-phone text-lg"></i>
      <span>+994 50 340 10 70</span>
    </div>
    <div className="flex items-center space-x-3">
      <i style={{color:'#5A639C'}} className="fa fa-envelope text-lg"></i>
      <span>info@pouffe.az</span>
    </div>
    <div className="flex items-center space-x-3">
      <i style={{color:'#5A639C'}} className="fa fa-map-marker text-lg"></i>
      <span>Nəsimi r. AK. Mirəli Qaşqay 28</span>
    </div>
  </div>
  
  <div className="flex flex-col space-y-4 w-full mt-6 md:w-1/2">
   <form onSubmit={handleSubmitContact}>
    <p className='font-medium'>Ad, soyad *</p>
   <input type="text" placeholder="Ad, soyad" className="w-full p-2 border mb-4 bg-[#F1F1F1] rounded-xl"
     onChange={(event) => setclientName(event.target.value)}
     value={clientName}
     required />
     <p className='font-medium'>E-mail *</p>
    <input type="email" placeholder="E-mail" className="w-full p-2 border mb-4 bg-[#F1F1F1] rounded-xl"
    onChange={(event) => setclientEmail(event.target.value)}
    value={clientEmail}
    required />
    <p className='font-medium'>Əlaqə nömrəsi *</p>
    <input type="number" placeholder="Əlaqə nömrəsi" className="w-full p-2 border mb-4 bg-[#F1F1F1] rounded-xl"
     onChange={(event) => setclientPhone(event.target.value)}
     value={clientPhone}
     required  />
     <p className='font-medium'>Mesaj *</p>
    <textarea placeholder="Bizə yazın..." className="w-full p-2 border mb-4 bg-[#F1F1F1] rounded-xl"
     onChange={(event) => setclientMessage(event.target.value)}
     value={clientMessage}
     required ></textarea>
    <button className="bg-[#5A639C] text-white py-2 px-6 rounded-xl w-[100%]">Mesaj göndər</button>
   </form>
  </div>
</div>

    </>
  )
} 

export default Contact