import React from 'react'
import DiscountCard from './DiscountCard';
import { discount1, discount2, discount3 } from '../../../assets/images';


const products = [
  {
    id: 1, 
    title: 'Bütün növ top pouffelara',
    discount: '20% Endirim',
    image: discount1,
    path: './top'
  },
  {
    id: 2,
    title: 'Bütün növ sofa pouffelara',
    discount: '20% Endirim',
    image: discount2,
    path: './sofa'
  },
  {
    id: 3,
    title: 'Bütün növ komfort pouffalara',
    discount: '20% Endirim',
    image: discount3,
    path: './komfort'
  },
];

const Discount = () => {
  return (
    <div className="max-w-container mx-auto grid grid-cols-1 lg:grid-cols-3 gap-6 px-4 py-8">
    {products.map((product) => (
     <a href={`/${product.path}`}>
       <DiscountCard
        key={product.id}
        title={product.title}
        discount={product.discount}
        image={product.image}
      />
     </a>
    ))} 
  </div>
  )
}

export default Discount 