import React from 'react'

const About = () => {
  return (
    <>
      <div className='h-40 bg-[#E3D5F5] text-center flex flex-col justify-end'>
        <h1 className='font-semibold text-2xl'>Haqqımızda</h1>
        <p className='mb-5'>Müasir və rahat pufiklər ilə evinizə yeni bir rahatlıq qatırıq</p>
      </div>
      <div className='max-w-container w-[80%] mx-auto pt-7 pb-7 '>
      <p className='text-center'>Biz kimik?</p>
      <p className='text-3xl text-center'>POUFFE.AZ</p>
       <div className='block md:flex mt-6 mb-12 justify-between'>
       <div className='md:w-1/2'>
        <p>“Pouffe Az” MMC müasir, yüksək keyfiyyətli və ekoloji cəhətdən təmiz materiallardan hazırlanmış çərçivəsiz kreslolar və puflar istehsalçısıdır. Biz bazarda sərfəli məhsulların topdan və pərakəndə alışına görə keyfiyyət-qiymət nisbətinə ilə fərqlənirik. Geniş istehsal gücünə malik müəssisələrimiz fəaliyyətdədir. Məhsulları sifarişçilərə operativ çatdıqmağa imkan verən işlək logistika şəbəkəsinə malikik. Biz sizə pərakəndə ticarətin bütün üstünlüklərini nəzərə almaqla aşağıdakı əməkdaşlıq təkliflərini edirik:</p>
        <ul className='text-left pl-4 w-full mx-auto'>
          <li>— 8-12 gün ərzində çatdırılma (və ya sifarişçinin istəyinə uyğun vaxtda);</li>
          <li>— nağd, kart və ya bank köçürməsi ilə ödəniş imkanı;</li>
          <li>— Anbarda hər zaman geniş rəng və ölçülərin mövcudluğu;</li>
          <li>— Bütün məhsullar üçün istehsalçıdan zəmanət;</li>
          <li>— Müəyyən etdiyiniz zövqə və meyarlara uyğun parça seçimi;</li>
          <li>— Seçdiyiniz parçalar üzrə məhsulların hazırlanması;</li>
          <li>— Məhsulları istəyinizə uyğun dizayn üzrə tikmək imkanı;</li>
          <li>— Böyük sifarişlər üçün (yüzlərlə məhsul) logistika təminatı;</li>
          <li>— İri həcmli alışlar üçün endirimlər</li>
        </ul>
        <p>Məhsullarımızı əldə etmək istəyən şirkətlərə və sahibkarlara, şəhər/rayonlara onlayn-mağaza formatını da təqdim edirik. Seçilmiş məhsullar üçün brendinq xidmətləri, eləcə də məhsulların korporativ üslub rənglərində tikilməsi göstərdiyimiz xidmətlər sırasındadır. Təqdimatın növbəti səhifələrində ilkin mərhələdə təklif olunan qiymətlər göstərilir.</p>

       </div>
       <div className='mt-5 md:w-2/5'>
        <img src='./about-img.png'></img>
       </div>
       </div>
      </div>
    </>
  )
}

export default About
