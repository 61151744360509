import React from 'react'

const DiscountCard = ({ title, discount, image }) => {
  return (
    <div className="relative bg-gray-200 rounded-lg overflow-hidden shadow-md max-h-[240px] max-w-[400px] mx-auto">
      <img src={image} alt={title} className=" object-cover" />
      <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-end p-4">
        <h3 className="text-white text-lg font-semibold">{title}</h3>
        <p className="text-[#E3D5F5] text-xl font-bold">{discount}</p>
        <button className="mt-4 px-4 py-2 bg-[#5A639C] text-white rounded-full w-[150px]">
          İndi al
        </button>
      </div>
    </div>
  ) 
}

export default DiscountCard