import React from 'react'
import ProductCard from './ProductCard';
import { product1, product2, product3, product4 } from '../../../assets/images';

const products = [
  {
    id: 1,
    title: 'Sofa pouffe',
    price: '100 Azn',
    image: product1, // Replace with the actual image path
    path: '/sofa'
  },
  {
    id: 2,
    title: 'Top pouffe',
    price: '100 Azn',
    image: product2, // Replace with the actual image path
     path: '/top'
  },
  {
    id: 3,
    title: 'Komfort pouffe',
    price: '100 Azn',
    image: product3, // Replace with the actual image path
    path:'/komfort',
    
  },
  {
    id: 4,
    title: 'Klassik pouffe',
    price: '100 Azn',
    image: product4, // Replace with the actual image path
     path: '/klassik'
  },
];

const Products = () => {
  return (
    <div className="max-w-7xl mx-auto py-8 mt-7 mb-7 px-4">
    <p className="text-center text-2xl sm:text-3xl font-medium mb-6">Məhsullar</p>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      {products.map((product) => (
        <a href={product.path} key={product.id}>
          <ProductCard
            title={product.title}
            price={product.price}
            image={product.image}
          />
        </a>
      ))}
    </div>
  </div>
  
  )
}

export default Products