import axios from "axios";
import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { Link, useNavigate } from "react-router-dom";

export default function DashboardContact() {
  const naviget = useNavigate();
  function logoutSubmit() {
    localStorage.setItem("login", "");
    localStorage.setItem("loginStatus", "Logged out successfully!");
    naviget("/");
  }
  const user = localStorage.getItem("user");

  const [products, setProducts] = useState([]);
  useEffect(() => {
    getProducts();
  }, []);

  function getProducts() {
    axios
      .get("https://www.pouffe.az/pouffe/contact.php/", {
        withCredentials: false
    })
      .then(function (response) {
        setProducts(response.data);
      });
  }

  const deleteUser = (id) => {
    axios
      .delete(`https://www.pouffe.az/pouffe/contact.php/${id}/delete`, {
        withCredentials: false
    })
      .then(function (response) {
        getProducts();
      });
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container"></div>
      </nav>
      <div className="container" style={{ paddingTop: 50 }}>
        <h3>{user}</h3>
        <div className="d-flex">
          <button
            className="btn btn-outline-primary"
            type="submit"
            onClick={logoutSubmit}
          >
            Çıxış
          </button>
        </div>
        <li>
          <Link style={{color:'black'}} to="/dashboard">Sifarişlər</Link>
        </li>
        <div>
          <h1>Əlaqə</h1>
          <table>
            <thead>
              <tr>
              <th>Actions</th>
                <th>ID</th>
                <th>Ad</th>
                <th>Telefon</th>
                <th>Email</th>
                <th>Mesaj</th>
              </tr>
            </thead>
            <tbody>
              {products.map((user, key) => (
                <tr key={key}>
                  <td>
                    {/* <Link
                      to={`/dashboard/${user.id}/edit`}
                      style={{ marginRight: "10px" }}
                    >
                      Edit
                    </Link> */}
                    <button onClick={() => deleteUser(user.id)}>Sil</button>
                  </td>
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{user.phone}</td>
                  <td>{user.email}</td>
                  <td>{user.message}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
