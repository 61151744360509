import axios from "axios";
import React, { useState, useEffect  } from "react";
import { useNavigate } from 'react-router-dom';
 
export default function AdminPanel() {
    const naviget = useNavigate();
    const [user, setUser] = useState("");
    const [pass, setPass] = useState("");
    const [error, setError] = useState(""); 
    const [msg, setMsg] = useState("");
 
    useEffect(() => {
        let login = localStorage.getItem("login");
        if(login){
            naviget("/dashboard");
        }
        let loginStatus = localStorage.getItem("loginStatus");
        if(loginStatus){
            setError(loginStatus);
            setTimeout(function(){
                localStorage.clear();
                window.location.reload();
            }, 3000);
        }
        setTimeout(function(){
            setMsg("");
        }, 5000);
    }, [msg]);
 
    const handleInputChange = (e, type) => {
        switch(type){
            case "user":
                setError("");
                setUser(e.target.value);
                if(e.target.value === ""){
                    setError("Username has left blank");
                }
                break;
            case "pass":
                setError("");
                setPass(e.target.value);
                if(e.target.value === ""){
                    setError("Password has left blank");
                }
                break;
            default:
        }
    }

    // const loginSubmit = async () => {
    //     try {
    //       const response = await axios.post('https://www.posstore.az/posstore/login.php', {
    //         user: user,
    //         pass: pass
    //       }, {
    //         headers: {
    //           'Content-Type': 'application/json'
    //         }
    //       });
    //       localStorage.setItem("login", true);
    //       localStorage.setItem('user', user);
    //       naviget("/dashboard");
    //       console.log(response.data);
    //     } catch (error) {
    //       console.error('Error:', error);
    //     }
    //   };



      const loginSubmit = async () => {
        try {
          const response = await axios.post('https://www.pouffe.az/pouffe/login.php', {
            user: user,
            pass: pass
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
      
          console.log(response.data);
      
          // Gelen yanıtın success olup olmadığını kontrol edin
          if (response.data.success) {
            // Success ise yapılacak işlemler
            localStorage.setItem("login", true);
          localStorage.setItem('user', user);
          naviget("/dashboard");
            // Buraya success durumunda yapılacak diğer işlemleri ekleyebilirsiniz.
          } else {
            console.log("Giriş başarısız:", response.data.result);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };
      
 
   
    return(
        <>
        <section className="vh-140" style={{backgroundColor: '#9A616D'}}>
        <div className="container py-5 h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col col-xl-10">
                <div className="card" style={{borderRadius: '1rem'}}>
                <div className="row g-0">
                    <div className="col-md-6 col-lg-5 d-none d-md-block">
                    <img src="https://images.pexels.com/photos/4348403/pexels-photo-4348403.jpeg?auto=compress&cs=tinysrgb&w=1600" alt="login form" className="img-fluid" style={{borderRadius: '1rem 0 0 1rem'}} />
                    </div>
                    <div className="col-md-6 col-lg-7 d-flex align-items-center">
                    <div className="card-body p-4 p-lg-5 text-black">
                        <p>
                            {
                                error !== "" ?
                                <div style={{color: '#842029'}}><b>{error}</b></div> :
                                <div style={{color: '#badbcc'}}><b>{msg}</b></div>
                            }
                        </p>
                        <div className="d-flex align-items-center mb-3 pb-1">
                            <i className="fas fa-cubes fa-2x me-3" style={{color: '#ff6219'}} />
                            <span className="h1 fw-bold mb-0">Logo</span>
                        </div>
                        <h5 className="fw-normal mb-3 pb-3" style={{letterSpacing: 1}}>Sign into your account</h5>
                        <div className="form-outline mb-4">
                            <input 
                                type="text"
                                id="username"
                                className="form-control form-control-lg"
                                value={user}
                                onChange={(e) => handleInputChange(e, "user")}
                            />
                            <label className="form-label" htmlFor="username">User Name</label>
                        </div>
                        <div className="form-outline mb-4">
                            <input 
                                type="password"
                                id="pass"
                                className="form-control form-control-lg"
                                value={pass}
                                onChange={(e) => handleInputChange(e, "pass")}
                            />
                            <label className="form-label" htmlFor="pass">Password</label>
                        </div>
                        <div className="pt-1 mb-4">
                            <input 
                                type="submit"
                                defaultValue="Login"
                                className="btn btn-dark btn-lg btn-block"
                                onClick={loginSubmit}
                            />
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
        </>
    )
}