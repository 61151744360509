export const navList = [
  {
    id: 1,
    path: "/",
    text: "ƏSAS",
  },
  {
    id: 2,
    path: "/about",
    text: "HAQQIMIZDA",
  },
  {
    id: 3,
    path: "/customers",
    text: "NİYƏ BİZ",
  },
  {
    id: 4,
    path: "/services",
    text: "ÜSTÜNLÜKLƏRİMİZ",
  },
  {
    id: 5,
    path: "/page",
    text: "MƏHSULLAR",
    subItems: [
      {
        id: 59,
        path: "/products",
        text: "Bütün məhsullar",
      },
      {
        id: 51,
        path: "/klassik",
        text: "Klassik",
      },
      {
        id: 52,
        path: "/komfort",
        text: "Komfort",
      },
      {
        id: 53,
        path: "/sofa",
        text: "Sofa",
      },
      {
        id: 55,
        path: "/top",
        text: "Top",
      }
    ],
  },
  {
    id: 6,
    path: "/contact",
    text: "ƏLAQƏ",
  },
];
export const socialIcons = [
  {
    icon: <i className="fab fa-facebook-f"></i>,
  },
  {
    icon: <i className="fab fa-twitter"></i>,
  },
  {
    icon: <i className="fab fa-instagram"></i>,
  },
  {
    icon: <i className="fab fa-linkedin-in"></i>,
  },
  {
    icon: <i className="fab fa-youtube"></i>,
  },
];

export const carouselData = [
  {
    img: "../assets/img/slider1.jpg",
    title: "Klassik",
    subtitle: "Pouffe",
    btn1: "Ətraflı",
    btn2: "Bütün məhsullar",
    path: "/klassik"
  },
  {
    img: "../assets/img/carousel2.jpg",
    title: "Sofa",
    subtitle: "Pouffe",
    btn1: "Ətraflı",
    btn2: "Bütün məhsullar",
     path: "/sofa"
  },
  {
    img: "../assets/img/slider1.jpg",
    title: "Komfort",
    subtitle: "Pouffe",
    btn1: "Ətraflı",
    btn2: "Bütün məhsullar",
     path: "/komfort"
  },
  {
    img: "../assets/img/carousel2.jpg",
    title: "Top",
    subtitle: "Pouffe",
    btn1: "Ətraflı",
    btn2: "Bütün məhsullar",
     path: "/top"
  },
];
export const about = [
  {
    icon: <i class="fa fa-hotel fa-2x text-primary mb-2"></i>,
    text: "İstifadəçilər",
    count: "7861",
  },
  {
    icon: <i class="fa fa-users fa-2x text-primary mb-2"></i>,
    text: "Əməkdaş",
    count: "80",
  },
  {
    icon: <i class="fa fa-users-cog fa-2x text-primary mb-2"></i>,
    text: "Müştərilərimiz",
    count: "130",
  },
];

export const products = [
  {
    image: "../assets/img/klassik.png",
    name: "Klassik",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik"
  },
  {
    image: "../assets/img/komfort.png",
    name: "Komfort",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort"
  },
  {
    image: "../assets/img/sofa.png",
    name: "Sofa",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/sofa"
  },

  {
    image: "../assets/img/top.png",
    name: "Top",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/top"
  },

];
 
export const top = [
  {
    image: "../assets/Top/top1.png",
    name: "Ağ/qara",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
     colorCode: "#E4E4E4",
    secondColorCode: "#010101"
  },
  {
    image: "../assets/Top/top2.png",
    name: "Qara/ağ",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort",
     colorCode: "#010101",
    secondColorCode: "#E4E4E4"
  },
  {
    image: "../assets/Top/top3.png",
    name: "Ağ/qırmızı",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/sofa",
     colorCode: "#E4E4E4",
    secondColorCode: "#9D1313"
  },
  {
    image: "../assets/Top/top4.png",
    name: "Qırmızı/ağ",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/top",
     colorCode: "#9D1313",
    secondColorCode: "#E4E4E4"
  },
  {
    image: "../assets/Top/top5.png",
    name: "Ağ/mavi",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
     colorCode: "#E4E4E4",
    secondColorCode: "#136E90"
  },
  {
    image: "../assets/Top/top6.png",
    name: "Mavi/ağ",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort",
     colorCode: "#136E90",
    secondColorCode: "#E4E4E4"
  },
  {
    image: "../assets/Top/top7.png",
    name: "Sarı/yaşıl",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/sofa",
     colorCode: "#C7AD0C",
    secondColorCode: "#336133"
  },
  {
    image: "../assets/Top/top8.png",
    name: "Yaşıl/sarı",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/top",
     colorCode: "#336133",
    secondColorCode: "#C7AD0C"
  },
  {
    image: "../assets/Top/top9.png",
    name: "Sarı/mavi",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
     colorCode: "#C7AD0C",
    secondColorCode: "#1981A0"
  },
  {
    image: "../assets/Top/top10.png",
    name: "Sarı/qara",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort",
     colorCode: "#C7AD0C",
    secondColorCode: "#000000"
  },
  {
    image: "../assets/Top/top11.png",
    name: "Qırmızı/yaşıl",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/sofa",
     colorCode: "#DE3838",
    secondColorCode: "#346133"
  },
  {
    image: "../assets/Top/top12.png",
    name: "Qırmızı/mavi",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/top",
    colorCode: "#DE3838",
    secondColorCode: "#136C8D"
  },
  {
    image: "../assets/Top/top13.png",
    name: "Narıncı/qara",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
     colorCode: "#F16718",
    secondColorCode: "#000000"
  },
  {
    image: "../assets/Top/top14.png",
    name: "Mavi/qara",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort",
    colorCode: "#136C8D",
    secondColorCode: "#000000"
  }
];

export const sofa = [
  {
    image: "../assets/Sofa/sofa1.png",
    name: "Bej",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
    l: '60x90',
    xl: '80x110',
    xxl: '95x135',
    xxxl: '110x150',
        colorCode: "#C6AC83"
  },
  {
    image: "../assets/Sofa/sofa2.png",
    name: "Qəhvəyi",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort",
     colorCode: "#654B33"
  },
  {
    image: "../assets/Sofa/sofa3.png",
    name: "Yaşıl",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/sofa",
     colorCode: "#569955"
  },
  {
    image: "../assets/Sofa/sofa4.png",
    name: "T.Yaşıl",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/top",
    colorCode: "#46633E"
  },
  {
    image: "../assets/Sofa/sofa5.png",
    name: "Xaki",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
     colorCode: "#514314"
  },
  {
    image: "../assets/Sofa/sofa6.png",
    name: "A.Yaşıl",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort",
    colorCode: "#B1CF12"
  },
  {
    image: "../assets/Sofa/sofa7.png",
    name: "Laym",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/sofa",
    colorCode: "#D2DC45"
  },
  {
    image: "../assets/Sofa/sofa8.png",
    name: "Sarı",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/top",
   colorCode: "#F1D012"
  },
  {
    image: "../assets/Sofa/sofa9.png",
    name: "Narıncı",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
    colorCode: "#E36529"
  },
  {
    image: "../assets/Sofa/sofa10.png",
    name: "Nion",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort",
    colorCode: "#ED8019"
  },
  {
    image: "../assets/Sofa/sofa11.png",
    name: "Qırmızı",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/sofa",
   colorCode: "#D91C1C"
  },
  {
    image: "../assets/Sofa/sofa12.png",
    name: "Bordo",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/top",
   colorCode: "#8D2C28"
  },
  {
    image: "../assets/Sofa/sofa13.png",
    name: "Bənövşə",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
    colorCode: "#692B64"
  },
  {
    image: "../assets/Sofa/sofa14.png",
    name: "Boz",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort",
    colorCode: "#9C9990"
  },
  {
    image: "../assets/Sofa/sofa15.png",
    name: "Mavi",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/sofa",
     colorCode: "#45CAED"
  },
  {
    image: "../assets/Sofa/sofa16.png",
    name: "Göy",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/top",
     colorCode: "#5C6387"
  },
  {
    image: "../assets/Sofa/sofa17.png",
    name: "T.Göy",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
     colorCode: "#282A2D"
  },
  {
    image: "../assets/Sofa/sofa18.png",
    name: "T.Boz",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort",
   colorCode: "#4D4F42"
  },
  {
    image: "../assets/Sofa/sofa19.png",
    name: "Qara",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/sofa",
    colorCode: "#1F1F1F"
  },
  {
    image: "../assets/Sofa/sofa20.png",
    name: "Ağ",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/top",
     colorCode: "#F0F0F0"
  }
];

export const komfort = [
  {
    image: "../assets/Komfort/komfort1.png",
    name: "Bej",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
    colorCode: "#C6AC83"
  },
  {
    image: "../assets/Komfort/komfort2.png",
    name: "Qəhvəyi",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort",
    colorCode: "#654B33"
  },
  {
    image: "../assets/Komfort/komfort3.png",
    name: "Yaşıl",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/sofa",
    colorCode: "#569955"
  },
  {
    image: "../assets/Komfort/komfort4.png",
    name: "T.Yaşıl",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/top",
    colorCode: "#46633E"
  },
  {
    image: "../assets/Komfort/komfort5.png",
    name: "Xaki",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
    colorCode: "#514314"
  },
  {
    image: "../assets/Komfort/komfort6.png",
    name: "A.Yaşıl",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort",
    colorCode: "#B1CF12"
  },
  {
    image: "../assets/Komfort/komfort7.png",
    name: "Laym",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/sofa",
    colorCode: "#D2DC45"
  },
  {
    image: "../assets/Komfort/komfort8.png",
    name: "Sarı",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/top",
    colorCode: "#F1D012"
  },
  {
    image: "../assets/Komfort/komfort9.png",
    name: "Narıncı",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
    colorCode: "#E36529"
  },
  {
    image: "../assets/Komfort/komfort10.png",
    name: "Nion",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort",
    colorCode: "#ED8019"
  },
  {
    image: "../assets/Komfort/komfort11.png",
    name: "Qırmızı",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/top",
    colorCode: "#D91C1C"
  },
  {
    image: "../assets/Komfort/komfort12.png",
    name: "Bordo",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
    colorCode: "#8D2C28"
  },
  {
    image: "../assets/Komfort/komfort13.png",
    name: "Bənövşə",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort",
    colorCode: "#692B64"
  },
  {
    image: "../assets/Komfort/komfort14.png",
    name: "Boz",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/sofa",
    colorCode: "#9C9990"
  },
  {
    image: "../assets/Komfort/komfort15.png",
    name: "Mavi",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/top",
    colorCode: "#45CAED"
  },
  {
    image: "../assets/Komfort/komfort16.png",
    name: "Göy",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
    colorCode: "#5C6387"
  },
  {
    image: "../assets/Komfort/komfort17.png",
    name: "T.Göy",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort",
    colorCode: "#282A2D"
  },
  {
    image: "../assets/Komfort/komfort18.png",
    name: "T.Boz",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/sofa",
    colorCode: "#4D4F42"
  },
  {
    image: "../assets/Komfort/komfort19.png",
    name: "Qara",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/top",
    colorCode: "#1F1F1F"
  },
  {
    image: "../assets/Komfort/komfort20.png",
    name: "Ağ",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
    colorCode: "#F0F0F0"
  },
  {
    image: "../assets/Komfort/komfort21.png",
    name: "Qəhvəyi/bej",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort",
    colorCode: "#715B47",
    secondColorCode: "#CCB590"
  },
  {
    image: "../assets/Komfort/komfort22.png",
    name: "Bej/qəhvəyi",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/sofa",
    colorCode: "#CCB590",
    secondColorCode: "#715B47"
  },
  {
    image: "../assets/Komfort/komfort23.png",
    name: "Laym/mavi",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/top",
    colorCode: "#DCE749",
    secondColorCode: "#3AC7EB"
  },
  {
    image: "../assets/Komfort/komfort24.png",
    name: "Mavi/laym",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
    colorCode: "#3AC7EB",
    secondColorCode: "#DCE749"
  },
  {
    image: "../assets/Komfort/komfort25.png",
    name: "Bordo/boz",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort",
    colorCode: "#800000",
    secondColorCode: "#9E9B92"
  },
  {
    image: "../assets/Komfort/komfort26.png",
    name: "Boz/bordo",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/sofa",
    colorCode: "#9E9B92",
    secondColorCode: "#800000"
  },
  {
    image: "../assets/Komfort/komfort27.png",
    name: "Narıncı/qırmızı",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/top",
    colorCode: "#E67440",
    secondColorCode: "#DE3939"
  },
  {
    image: "../assets/Komfort/komfort28.png",
    name: "Qırmızı/narıncı",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
    colorCode: "#DE3939",
    secondColorCode: "#E67440"
  },
  {
    image: "../assets/Komfort/komfort29.png",
    name: "Narıncı/ağ",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort",
    colorCode: "#E67440",
    secondColorCode: "#FDFDFD"
  },
  {
    image: "../assets/Komfort/komfort30.png",
    name: "Yaşıl/sarı",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/sofa",
    colorCode: "#6BA76A",
    secondColorCode: "#F3D72B"
  },
  {
    image: "../assets/Komfort/komfort31.png",
    name: "Mavi/sarı",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/top",
    colorCode: "#43CAEC",
    secondColorCode: "#F3D72B"
  },
  {
    image: "../assets/Komfort/komfort32.png",
    name: "Qara/sarı",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
    colorCode: "#232323",
    secondColorCode: "#F3D72B"
  },
  {
    image: "../assets/Komfort/komfort33.png",
    name: "Göy/sarı",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort",
    colorCode: "#445090",
    secondColorCode: "#F3D41A"
  },
  {
    image: "../assets/Komfort/komfort34.png",
    name: "Sarı/göy",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/sofa",
    colorCode: "#F3D41A",
    secondColorCode: "#445090"
  },
  {
    image: "../assets/Komfort/komfort35.png",
    name: "Göy/laym",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/top",
    colorCode: "#424E8F",
    secondColorCode: "#ECF751"
  },
  {
    image: "../assets/Komfort/komfort36.png",
    name: "Laym/göy",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
    colorCode: "#ECF751",
    secondColorCode: "#424E8F"
  },
];


export const klassik = [
  {
    image: "../assets/Klassik/klassik1.png",
    name: "Bej",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
    colorCode: "#C6AC83"
  },
  {
    image: "../assets/Klassik/klassik2.png",
    name: "Qəhvəyi",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort",
    colorCode: "#654B33"
  },
  {
    image: "../assets/Klassik/klassik3.png",
    name: "Yaşıl",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/sofa",
    colorCode: "#569955"
  },
  {
    image: "../assets/Klassik/klassik4.png",
    name: "T.Yaşıl",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/top",
    colorCode: "#46633E"
  },
  {
    image: "../assets/Klassik/klassik5.png",
    name: "Xaki",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
    colorCode: "#514314"
  },
  {
    image: "../assets/Klassik/klassik6.png",
    name: "A.Yaşıl",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort",
    colorCode: "#B1CF12"
  },
  {
    image: "../assets/Klassik/klassik7.png",
    name: "Laym",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/sofa",
    colorCode: "#D2DC45"
  },
  {
    image: "../assets/Klassik/klassik8.png",
    name: "Sarı",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/top",
    colorCode: "#F1D012"
  },
  {
    image: "../assets/Klassik/klassik9.png",
    name: "Narıncı",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
    colorCode: "#E36529"
  },
  {
    image: "../assets/Klassik/klassik10.png",
    name: "Nion",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort",
    colorCode: "#ED8019"
  },
  {
    image: "../assets/Klassik/klassik11.png",
    name: "Qırmızı",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/top",
    colorCode: "#D91C1C"
  },
  {
    image: "../assets/Klassik/klassik12.png",
    name: "Bordo",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
    colorCode: "#8D2C28"
  },
  {
    image: "../assets/Klassik/klassik13.png",
    name: "Bənövşə",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort",
    colorCode: "#692B64"
  },
  {
    image: "../assets/Klassik/klassik14.png",
    name: "Boz",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/sofa",
    colorCode: "#9C9990"
  },
  {
    image: "../assets/Klassik/klassik15.png",
    name: "Mavi",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/top",
    colorCode: "#45CAED"
  },
  {
    image: "../assets/Klassik/klassik16.png",
    name: "Göy",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
    colorCode: "#5C6387"
  },
  {
    image: "../assets/Klassik/klassik17.png",
    name: "T.Göy",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort",
    colorCode: "#282A2D"
  },
  {
    image: "../assets/Klassik/klassik18.png",
    name: "T.Boz",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/sofa",
    colorCode: "#4D4F42"
  },
  {
    image: "../assets/Klassik/klassik19.png",
    name: "Qara",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/top",
    colorCode: "#1F1F1F"
  },
  {
    image: "../assets/Klassik/klassik20.png",
    name: "Ağ",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
    colorCode: "#F0F0F0"
  },
  {
    image: "../assets/Klassik/klassik21.png",
    name: "Qəhvəyi/bej",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort",
       colorCode: "#715B47",
    secondColorCode: "#CCB590"
  },
  {
    image: "../assets/Klassik/klassik22.png",
    name: "Bej/qəhvəyi",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/sofa",
    colorCode: "#CCB590",
    secondColorCode: "#715B47"
  },
  {
    image: "../assets/Klassik/klassik23.png",
    name: "Laym/mavi",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/top",
    colorCode: "#DCE749",
    secondColorCode: "#3AC7EB"
  },
  {
    image: "../assets/Klassik/klassik24.png",
    name: "Mavi/laym",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
    colorCode: "#3AC7EB",
    secondColorCode: "#DCE749"
  },
  {
    image: "../assets/Klassik/klassik25.png",
    name: "Bordo/boz",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort",
     colorCode: "#800000",
    secondColorCode: "#9E9B92"
  },
  {
    image: "../assets/Klassik/klassik26.png",
    name: "Boz/bordo",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/sofa",
     colorCode: "#9E9B92",
    secondColorCode: "#800000"
  },
  {
    image: "../assets/Klassik/klassik27.png",
    name: "Narıncı/qırmızı",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/top",
     colorCode: "#E67440",
    secondColorCode: "#DE3939"
  },
  {
    image: "../assets/Klassik/klassik28.png",
    name: "Qırmızı/narıncı",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
     colorCode: "#DE3939",
    secondColorCode: "#E67440"
  },
  {
    image: "../assets/Klassik/klassik29.png",
    name: "Narıncı/ağ",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort",
      colorCode: "#E67440",
    secondColorCode: "#FDFDFD"
  },
  {
    image: "../assets/Klassik/klassik30.png",
    name: "Yaşıl/sarı",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/sofa",
   colorCode: "#6BA76A",
    secondColorCode: "#F3D72B"
  },
  {
    image: "../assets/Klassik/klassik31.png",
    name: "Mavi/sarı",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/top",
       colorCode: "#43CAEC",
    secondColorCode: "#F3D72B"
  },
  {
    image: "../assets/Klassik/klassik32.png",
    name: "Qara/sarı",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/klassik",
    colorCode: "#232323",
    secondColorCode: "#F3D72B"
  },
  {
    image: "../assets/Klassik/klassik33.png",
    name: "Göy/sarı",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/komfort",
     colorCode: "#445090",
    secondColorCode: "#F3D41A"
  },
  {
    image: "../assets/Klassik/klassik34.png",
    name: "Sarı/göy",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/sofa",
   colorCode: "#F3D41A",
    secondColorCode: "#445090"
  },
  {
    image: "../assets/Klassik/klassik35.png",
    name: "Göy/laym",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/top",
  colorCode: "#424E8F",
    secondColorCode: "#ECF751"
  },
  {
    image: "../assets/Klassik/klassik36.png",
    name: "Laym/göy",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/sofa",
    colorCode: "#ECF751",
    secondColorCode: "#424E8F"
  },
];

export const team = [
  {
    image: "../assets/img/team-1.jpg",
    name: "Full Name",
    designation: "Designation",
  },
  {
    image: "../assets/img/team-3.jpg",
    name: "Full Name",
    designation: "Designation",
  },
  {
    image: "../assets/img/team-1.jpg",
    name: "Full Name",
    designation: "Designation",
  },
  {
    image: "../assets/img/team-3.jpg",
    name: "Full Name",
    designation: "Designation",
  },
];

export const footerItem = [
  {
    id: 1,
    header: "Şirkətimiz",
    UnitItem: [
      {
        name: "Haqqımızda",
      },
      {
        name: "Üstünlüklərimiz",
      },
      {
        name: "Məhsullar",
      },
      {
        name: "Niyə Biz",
      },
      {
        name: "Əlaqə",
      },
    ],
  },
  {
    id: 2,
    header: "Məhsullar",
    UnitItem: [
      {
        name: "Bütün məhsullar",
      },
      {
        name: "Klassik",
      },
      {
        name: "Komfort",
      },
      {
        name: "Sofa",
      },
      {
        name: "Top",
      },
    ],
  },
];

export const footerContact = [
  {
    icon: <i className="fa fa-map-marker-alt me-3"></i>,
    name: "Nərimanov rayonu, Çaykovski 118.",
  },
  {
    icon: <i className="fa fa-phone-alt me-3"></i>,
    name: "(+994) 50 340 10 70",
  },
  {
    icon: <i className="fa fa-envelope me-3"></i>,
    name: "info@pouffe.az",
  },
];

export const contact = [
  {
    icon: <i class="fa fa-map-marker text-primary me-2"></i>,
    title: "Ünvan",
    email: "Nərimanov rayonu, Çaykovski 118.",
  },
  {
    icon: <i class="fa fa-phone text-primary me-2"></i>,
    title: "Telefon",
    email: "(+994) 50 340 10 70",
  },
  {
    icon: <i class="fa fa-envelope-open text-primary me-2"></i>,
    title: "Email",
    email: "info@pouffe.az",
  },
  
];
export const testimonial = [
  {
    description:
      "Tempor stet labore dolor clita stet diam amet ipsum dolor duo ipsum rebum stet dolor amet diam stet. Est stet ea lorem amet est kasd kasd et erat magna eos",
    name: "Client Name",
    profession: "Profession",
    icon: (
      <i class="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-1.jpg",
  },
  {
    description:
      "Tempor stet labore dolor clita stet diam amet ipsum dolor duo ipsum rebum stet dolor amet diam stet. Est stet ea lorem amet est kasd kasd et erat magna eos",
    name: "Client Name",
    profession: "Profession",
    icon: (
      <i class="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-2.jpg",
  },
  {
    description:
      "Tempor stet labore dolor clita stet diam amet ipsum dolor duo ipsum rebum stet dolor amet diam stet. Est stet ea lorem amet est kasd kasd et erat magna eos",
    name: "Client Name",
    profession: "Profession",
    icon: (
      <i class="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-3.jpg",
  },
];

export const services = [
  {
    img: "../assets/img/services-1.png",
    price: "$110/night",
    name: "Data analitik üzrə xidmətlərin göstərilməsi",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
    yellowbtn: "Ətraflı",
    darkbtn: "Bütün xidmətlər",
  },

  {
    img: "../assets/img/services-2_optimized.png",
    price: "$110/night",
    name: "Satış və Anbarların idarə olunması üçün proqram təminatı",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
    yellowbtn: "Ətraflı",
    darkbtn: "Bütün xidmətlər",
  },
  {
    img: "../assets/img/services-3_optimized.png",
    price: "$110/night",
    name: "Maliyyə hesabatlarının idarə olunması üçün proqram təminatı",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
    yellowbtn: "Ətraflı",
    darkbtn: "Bütün xidmətlər",
  },
  {
    img: "../assets/img/services-4_optimized.png",
    price: "$110/night",
    name: "DBMS İnzibatçılığı və hesabatların çıxarılması",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
    yellowbtn: "Ətraflı",
    darkbtn: "Bütün xidmətlər",
  },
  {
    img: "../assets/img/services-5_1_optimized.png",
    price: "$110/night",
    name: "SSAS (SQL services analysis service) xidmətləri",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
    yellowbtn: "Ətraflı",
    darkbtn: "Bütün xidmətlər",
  },
  {
    img: "../assets/img/services-6_optimized.png",
    price: "$110/night",
    name: "SSAS cube-ların hazırlanması və viziuallaşması (power bi, excel)",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
    yellowbtn: "Ətraflı",
    darkbtn: "Bütün xidmətlər",
  },
  {
    img: "../assets/img/services-7_optimized.png",
    price: "$110/night",
    name: "APAche kafka realtime hesabatların hazırlanması",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
    yellowbtn: "Ətraflı",
    darkbtn: "Bütün xidmətlər",
  },
  {
    img: "../assets/img/services-8_optimized.png",
    price: "$110/night",
    name: "Hesabatların müqayisəsi, fərqlərin araşdırılması (SQL, Excel)",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
    yellowbtn: "Ətraflı",
    darkbtn: "Bütün xidmətlər",
  },
  {
    img: "../assets/img/services-9_optimized.png",
    price: "$110/night",
    name: "Microsoft Azure Cloud Synapse Analytics xidmətləri",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
    yellowbtn: "Ətraflı",
    darkbtn: "Bütün xidmətlər",
  },
  {
    img: "../assets/img/services-10_optimized.png",
    price: "$110/night",
    name: "DB-lərin (Oracle, SQL Server) inteqrasiya prosesləri (SSİS)",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
    yellowbtn: "Ətraflı",
    darkbtn: "Bütün xidmətlər",
  },
  {
    img: "../assets/img/services-11_optimized.png",
    price: "$110/night",
    name: "Destkop proqram proqram təminatlarının hazırlanması",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
    yellowbtn: "Ətraflı",
    darkbtn: "Bütün xidmətlər",
  },
  {
    img: "../assets/img/services-12_optimized.png",
    price: "$110/night",
    name: "Web proqram təminatlarının hazırlanması",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
    yellowbtn: "Ətraflı",
    darkbtn: "Bütün xidmətlər",
  },
  {
    img: "../assets/img/services-13_optimized.png",
    price: "$110/night",
    name: "Mobil tətbiqlərin hazırlanması",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
    yellowbtn: "Ətraflı",
    darkbtn: "Bütün xidmətlər",
  },
  {
    img: "../assets/img/services-14_optimized.png",
    price: "$110/night",
    name: "Anbar və satış proqramlarının bütün növ e-kassalara inteqrasiyası",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Erat ipsum justo  duo eos lorem sed diam stet diam sed stet lorem.",
    yellowbtn: "Ətraflı",
    darkbtn: "Bütün xidmətlər",
  },
];

export const servicesShort = [
  {
    img: "../assets/img/services-1.png",
    price: "$110/night",
    name: "Data analitik üzrə xidmətlərin göstərilməsi",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
    yellowbtn: "Ətraflı",
    darkbtn: "Bütün xidmətlər",
  },

  {
    img: "../assets/img/services-2_optimized.png",
    price: "$110/night",
    name: "Satış və Anbarların idarə olunması üçün proqram təminatı",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
    yellowbtn: "Ətraflı",
    darkbtn: "Bütün xidmətlər",
  },
  {
    img: "../assets/img/services-3_optimized.png",
    price: "$110/night",
    name: "Maliyyə hesabatlarının idarə olunması üçün proqram təminatı",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
    yellowbtn: "Ətraflı",
    darkbtn: "Bütün xidmətlər",
  },
  {
    img: "../assets/img/services-4_optimized.png",
    price: "$110/night",
    name: "DBMS İnzibatçılığı və hesabatların çıxarılması",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
    yellowbtn: "Ətraflı",
    darkbtn: "Bütün xidmətlər",
  },
  {
    img: "../assets/img/services-5_1_optimized.png",
    price: "$110/night",
    name: "SSAS (SQL services analysis service) xidmətləri",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
    yellowbtn: "Ətraflı",
    darkbtn: "Bütün xidmətlər",
  },
  {
    img: "../assets/img/services-6_optimized.png",
    price: "$110/night",
    name: "SSAS cube-ların hazırlanması və viziuallaşması (power bi, excel)",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
    yellowbtn: "Ətraflı",
    darkbtn: "Bütün xidmətlər",
  },
]

export const facility = [
  {
    icon: <i class="fa fa-bed text-primary me-2"></i>,
    quantity: 3,
    facility: "bed",
  },
  {
    icon: <i class="fa fa-bath text-primary me-2"></i>,
    quantity: 2,
    facility: "bath",
  },
  {
    icon: <i class="fa fa-wifi text-primary me-2"></i>,
    facility: "Wifi",
  },
];

export const price = [
  {
    plan: "Market",
    price: "29",
    ptext: "per user, per month",
    image: "../assets/img/partners/partners1.jpg",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "120GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "5GB Cloud Storage",
      },
      { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Personal Help Support" },
      { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Enterprise SLA" },
    ],
  },
  {
    best: "Best Value",
    plan: "Restoran",
    price: "49",
    ptext: "per user, per month",
    image: "../assets/img/partners/partners2.png",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "150GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "10GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        change: "color",
        icon: <i class='fa-solid fa-x'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners3.png",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Market",
    price: "29",
    ptext: "per user, per month",
    image: "../assets/img/partners/partners4.jpg",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "120GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "5GB Cloud Storage",
      },
      { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Personal Help Support" },
      { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Enterprise SLA" },
    ],
  },
  {
    best: "Best Value",
    plan: "Restoran",
    price: "49",
    ptext: "per user, per month",
    image: "../assets/img/partners/partners5.png",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "150GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "10GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        change: "color",
        icon: <i class='fa-solid fa-x'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners6.jpg",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners7.jpg",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners8.jpg",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners9.jpg",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners10.png",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners21.png",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners12.jpg",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners13.jpg",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners14.png",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners15.png",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners16.jpg",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners17.jpg",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners18.jpg",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners19.jpg",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners20.jpg",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },

  
]
export const priceShort = [
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners14.png",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners15.png",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners16.jpg",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners17.jpg",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners18.jpg",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners19.jpg",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners20.jpg",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
]
